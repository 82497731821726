const getDataSize = (size, decimalPlaces = 2) => {
  if (isNaN(size)) return '';

  const KiloBytes = size / 1024;
  const MegaBytes = KiloBytes / 1024;
  const GigaBytes = MegaBytes / 1024;

  if (KiloBytes < 1024) {
    return `${KiloBytes.toFixed(decimalPlaces)} KB`;
  }

  if (MegaBytes < 1024) {
    return `${MegaBytes.toFixed(decimalPlaces)} MB`;
  }

  return `${GigaBytes.toFixed(decimalPlaces)} GB`;
};

export default getDataSize;
