const productList = async (storeId = null, productStatus = null) => new Promise((resolve, reject) => {
  (async () => {
    try {
      let url = `${process.env.REACT_APP_API_FUNC_URL}/productlist?store_id=${storeId}`;
      if (productStatus !== null) {
        url = `${process.env.REACT_APP_API_FUNC_URL}/productlist?store_id=${storeId}&product_status=${productStatus}`;
      }
      const response = await fetch(url);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

export default productList;
