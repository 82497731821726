import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  getAllFiles, createFolder, deleteFile, downloadFile,
  copyFiles,
  moveFiles,
  renameFile,
} from '../../../services/AssetsService';

const useFileManager = () => {
  const fileUploadConfig = useMemo(() => ({
    url: `${import.meta.env?.VITE_API_BASE_URL}/upload`,
  }), []);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([
    {
      name: 'Content',
      isDirectory: true,
      path: '/Content',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Theme Collections',
      isDirectory: true,
      path: '/Content/Theme Collections',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Theme Collections/Display Stands',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Other stuff',
      isDirectory: true,
      path: '/Content/Theme Collections/Other stuff',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Interior',
      isDirectory: true,
      path: '/Content/Interior',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Interior/Display Stands',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Other stuff',
      isDirectory: true,
      path: '/Content/Interior/Other stuff',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Marketing Content',
      isDirectory: true,
      path: '/Content/Marketing Content',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Pic.png',
      isDirectory: false,
      path: '/Content/Pic.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Video.mp4',
      isDirectory: false,
      path: '/Content/Video.mp4',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 8048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Dsdfgfd.png',
      isDirectory: false,
      path: '/Content/Dsdfgfd.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
  ]);
  const isMountRef = useRef(false);

  // Get Files
  const getFiles = async () => {
    try {
      setIsLoading(true);
      const response = await getAllFiles();
      setFiles(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMountRef.current) return;
    isMountRef.current = true;
    // getFiles();
  }, []);
  //

  // Create Folder
  const handleCreateFolder = async (name, parentFolder) => {
    try {
      setIsLoading(true);
      const response = await createFolder(name, parentFolder?.id);
      setFiles((prev) => [...prev, response.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //

  // File Upload Handlers
  const handleFileUploading = (file, parentFolder) => ({
    // parentId: parentFolder?._id
  });

  const handleFileUploaded = (response) => {
    const uploadedFile = JSON.parse(response);
    setFiles((prev) => [...prev, uploadedFile]);
  };
  //

  // Rename File/Folder
  const handleRename = async (file, newName) => {
    try {
      setIsLoading(true);
      const response = await renameFile(file.id, newName);
      await getFiles();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  //

  // Delete File/Folder
  const handleDelete = async (filesData) => {
    try {
      setIsLoading(true);
      const idsToDelete = filesData.map((file) => file.id);
      const response = await deleteFile(idsToDelete);
      getFiles();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //

  // Paste File/Folder
  const handlePaste = async (copiedItems, destinationFolder, operationType) => {
    try {
      setIsLoading(true);
      const copiedItemIds = copiedItems.map((item) => item.id);
      if (operationType === 'copy') {
        const response = await copyFiles(copiedItemIds, destinationFolder?.id);
      } else {
        const response = await moveFiles(copiedItemIds, destinationFolder?.id);
      }
      await getFiles();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  //

  const handleLayoutChange = (layout) => {
    // console.log(layout);
  };

  // Refresh Files
  const handleRefresh = () => {
    getFiles();
  };
  //

  const handleFileOpen = (file) => {
    // console.log(`Opening file: ${file.name}`);
  };

  const handleError = (error, file) => {
    // console.error(error);
  };

  const handleDownload = async (filesData) => {
    await downloadFile(filesData);
  };

  return {
    fileUploadConfig,
    files,
    setFiles,
    isLoading,
    getFiles,
    handleCreateFolder,
    handleFileUploading,
    handleFileUploaded,
    handleRename,
    handleDelete,
    handlePaste,
    handleLayoutChange,
    handleRefresh,
    handleFileOpen,
    handleError,
    handleDownload,
  };
};

export default useFileManager;
