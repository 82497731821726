import { createContext, useContext, useState } from 'react';
import validateApiCallback from '../utils/validateApiCallback';

const SelectionContext = createContext();

const SelectionProvider = ({ children, onDownload }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDownload = () => {
    validateApiCallback(onDownload, 'onDownload', selectedFiles);
  };

  return (
    <SelectionContext.Provider value={{ selectedFiles, setSelectedFiles, handleDownload }}>
      {children}
    </SelectionContext.Provider>
  );
};

export default SelectionProvider;

export const useSelection = () => useContext(SelectionContext);
