import './Button.css';

const Button = ({
  onClick, onKeyDown, type = 'primary', padding = '0.4rem 0.8rem', children,
}) => (
  <button
    onClick={onClick}
    onKeyDown={onKeyDown}
    className={`fm-button fm-button-${type}`}
    style={{ padding }}
  >
    {children}
  </button>
);

export default Button;
