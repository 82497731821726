import { useEffect, useState } from 'react';
import {
  Badge, Button, Col, Form, InputGroup, Image, Table, Row,
} from 'react-bootstrap';
import Moment from 'react-moment';

import { getStoreSession } from '../../../services/StoreService';
import productList from '../../../services/ProductService';

const ProductList = ({ setDraggedItem }) => {
  const [selectedSearchText, setSelectedSearchText] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedProductStatus, setSelectedProductStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productStatus, setProductStatus] = useState([]);
  const store = getStoreSession();

  const getProducts = async () => {
    try {
      let response = null;
      if (selectedProductStatus) {
        response = await productList(store.id, selectedProductStatus);
      } else {
        response = await productList(store.id);
      }
      if (response.success) {
        setProducts(response.data);
      } else {
        setProducts([]);
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const getVendors = () => {
    const uniqueVendors = products
      .map((product) => product.vendor)
      .filter((value, index, self) => self.indexOf(value) === index);
    setVendors(uniqueVendors);
  };

  const getProductTypes = () => {
    const uniqueProductTypes = products
      .map((product) => product.product_type)
      .filter((value, index, self) => self.indexOf(value) === index);
    setProductTypes(uniqueProductTypes);
  };

  const getProductStatus = () => {
    setProductStatus([{ id: 1, name: 'Draft' }, { id: 2, name: 'Active' }, { id: 3, name: 'Archive' }]);
  };

  const getFilteredProducts = () => {
    if (selectedVendor || selectedProductType || selectedSearchText) {
      if (selectedVendor && selectedProductType && selectedSearchText) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.product_type === selectedProductType
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedProductType) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.product_type === selectedProductType);
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedSearchText) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedProductType && selectedSearchText) {
        const filterProds = products.filter((product) => product.product_type === selectedProductType
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedVendor) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor);
        setFilterProducts(filterProds);
      } else if (selectedProductType) {
        const filterProds = products.filter((product) => product.product_type === selectedProductType);
        setFilterProducts(filterProds);
      } else if (selectedSearchText) {
        const filterProds = products.filter((product) => product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      }
    } else {
      setFilterProducts(products);
    }
  };

  const getProductStatusBadge = (status) => {
    let badge = <Badge bg="success">Active</Badge>;
    if (status === 2) {
      badge = <Badge bg="success">Active</Badge>;
    } else if (status === 3) {
      badge = <Badge bg="danger">Archived</Badge>;
    } else {
      badge = <Badge bg="info">Draft</Badge>;
    }
    return badge;
  };

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductStatus]);

  useEffect(() => {
    getProductStatus();
    getVendors();
    getProductTypes();
    getFilteredProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (products) {
      getFilteredProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductType, selectedVendor, selectedSearchText]);

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <div className="d-flex align-items-center mb_2 py-1 myProduct">
            <h6 className="text-white mb-0 me-2">Products</h6>
            <InputGroup>
              <Button variant="outline-secondary" id="button-addon1">
                <i className="bi bi-search"></i>
              </Button>
              <Form.Control
                placeholder="Search Products"
                onChange={(e) => setSelectedSearchText(e.target.value)}
                value={selectedSearchText}
              />
            </InputGroup>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="py-1 pe-3">
            <InputGroup>
              <div className="position-relative">
                <Form.Select
                  size="sm"
                  onChange={(e) => setSelectedProductType(e.target.value)}
                  value={selectedProductType}
                >
                  <option value="">Type</option>
                  {productTypes
                    && productTypes.map((productType) => (<option key={productType} value={productType}>
                      {productType}</option>))}
                </Form.Select>
              </div>
              <div className="position-relative">
                <Form.Select
                  size="sm"
                  onChange={(e) => setSelectedProductStatus(e.target.value)}
                  value={selectedProductStatus}
                >
                  <option value="">Status</option>
                  {productStatus
                    && productStatus.map((productSts) => (
                    <option key={productSts.id} value={productSts.id}>{productSts.name}</option>))}
                </Form.Select>
              </div>
              <div className="position-relative">
                <Form.Select
                  size="sm"
                  onChange={(e) => setSelectedVendor(e.target.value)}
                  value={selectedVendor}
                >
                  <option value="">Vendor</option>
                  {vendors && vendors.map((vendor) => <option key={vendor} value={vendor}>{vendor}</option>)}
                </Form.Select>
              </div>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="productListTable">
          <Table striped bordered hover responsive variant="dark" size='sm'>
            <thead>
              <tr>
                <th>3D Model</th>
                <th>Product</th>
                <th>Type</th>
                <th>Status</th>
                <th>Vendor</th>
                <th>Last Synchronized</th>
              </tr>
            </thead>
            <tbody>
              {filterProducts && filterProducts.map((eachProduct) => (
                <tr key={eachProduct.id}>
                  <td>
                    <Image
                      src={eachProduct.Media?.[0]?.file_url}
                      thumbnail
                      width={50}
                      height={50}
                      alt="product"
                      draggable={true}
                      onDragStart={(e) => {
                        setDraggedItem({
                          id: eachProduct.id,
                          name: eachProduct.name,
                          src: eachProduct.Media?.[0]?.file_url,
                          isProduct: true,
                          height: 100,
                          width: 100,
                        });
                      }}
                    />
                  </td>
                  <td>{eachProduct.name}</td>
                  <td>{eachProduct.product_type}</td>
                  <td>{getProductStatusBadge(eachProduct.product_status)}</td>
                  <td>{eachProduct.vendor}</td>
                  <td><Moment format='DD-MM-YYYY HH:mm A'>{eachProduct.created_at}</Moment></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ProductList;
