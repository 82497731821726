import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import MerchantNavbar from '../components/merchant/MerchantNavbar';

import useAuth from '../hooks/useAuth';

function MerchantLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  if (auth.isAuth()) {
    return (
      <>
        <MerchantNavbar />
        <div className="container-fluid">
          <div className="row">
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <Outlet />
              <Footer />
            </main>
          </div>
        </div>
      </>
    );
  }

  return <Navigate to={`/auth/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default MerchantLayout;
