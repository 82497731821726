const shortcuts = {
  createFolder: ['Alt', 'Shift', 'N'],
  uploadFiles: ['Control', 'U'],
  cut: ['Control', 'X'],
  copy: ['Control', 'C'],
  paste: ['Control', 'V'],
  rename: ['F2'],
  download: ['Control', 'D'],
  delete: ['Delete'],
  selectAll: ['Control', 'A'],
  selectArrows: ['Shift', 'Arrows'], // (pending)
  navigation: ['Arrows'], // (pending)
  jumpToFirst: ['Home'],
  jumpToLast: ['End'],
  listLayout: ['Control', 'Shift', '!'], // Act as Ctrl + Shift + 1 but could cause problems for QWERTZ or DVORAK etc. keyborad layouts.
  gridLayout: ['Control', 'Shift', '@'], // Act as Ctrl + Shift + 2 but could cause problems for QWERTZ or DVORAK etc. keyborad layouts.
  refresh: ['F5'],
  clearSelection: ['Escape'],
};

export default shortcuts;
