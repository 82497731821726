import { Image } from 'react-bootstrap';

const File = ({ item, selectedObject, handleSelectedItem }) => {
  const handleSelectedData = (data) => {
    handleSelectedItem(data);
  };

  const wordWrapWithEllipsis = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength - 3)}...`;
  };

  return (
    <span
      key={`file_${item.id}`}
      onClick={() => handleSelectedData(item)}
      className={selectedObject.id === item.id ? 'imgitem selected' : 'imgitem'}
      title={item.name}
    >
      <Image src={item.src} width={16} height={16} alt='folder' /> {wordWrapWithEllipsis(item.name, 25)}
    </span>
  );
};

export default File;
