import { useState } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import { MdOutlineFileDownload } from 'react-icons/md';
import getFileExtension from '../../../../../utils/getFileExtension';
import Loader from '../../../../Loader/Loader';
import { useSelection } from '../../../../../contexts/SelectionContext';
import Button from '../../../../Button/Button';
import getDataSize from '../../../../../utils/getDataSize';
import useFileIcons from '../../../../../hooks/useFileIcons';
import './PreviewFile.action.css';

const imageExtensions = ['jpg', 'jpeg', 'png'];
const videoExtensions = ['mp4', 'mov', 'avi'];
const audioExtensions = ['mp3', 'wav', 'm4a'];
const iFrameExtensions = ['txt', 'pdf'];

const PreviewFileAction = ({ filePreviewPath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { selectedFiles } = useSelection();
  const fileIcons = useFileIcons(73);
  const extension = getFileExtension(selectedFiles[0].name)?.toLowerCase();
  const filePath = `${filePreviewPath}${selectedFiles[0].path}`;

  const handleImageLoad = () => {
    setIsLoading(false); // Loading is complete
    setHasError(false); // No error
  };

  const handleImageError = () => {
    setIsLoading(false); // Loading is complete
    setHasError(true); // Error occurred
  };

  const handleDownload = () => {
    window.location.href = filePath;
  };

  return (
    <section className={`file-previewer ${extension === 'pdf' ? 'pdf-previewer' : ''}`}>
      {hasError || (
        ![
          ...imageExtensions,
          ...videoExtensions,
          ...audioExtensions,
          ...iFrameExtensions,
        ].includes(extension) && (
          <div className='preview-error'>
            <span className='error-icon'>{fileIcons[extension] ?? <FaRegFileAlt size={73} />}</span>
            <span className='error-msg'>Sorry! Preview is not available for this file.</span>
            <div className='file-info'>
              <span className='file-name'>{selectedFiles[0].name}</span>
              {selectedFiles[0].size && <span>-</span>}
              <span className='file-size'>{getDataSize(selectedFiles[0].size)}</span>
            </div>
            <Button onClick={handleDownload} padding='0.45rem .9rem'>
              <div className='download-btn'>
                <MdOutlineFileDownload size={18} />
                <span>Download</span>
              </div>
            </Button>
          </div>
        )
      )}
      {imageExtensions.includes(extension) && (
        <>
          <Loader isLoading={isLoading} />
          <img
            src={filePath}
            alt='Preview Unavailable'
            className={`photo-popup-image ${isLoading ? 'img-loading' : ''}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
            loading='lazy'
          />
        </>
      )}
      {videoExtensions.includes(extension) && (
        <video src={filePath} className='video-preview' controls autoPlay />
      )}
      {audioExtensions.includes(extension) && (
        <audio src={filePath} controls autoPlay className='audio-preview' />
      )}
      {iFrameExtensions.includes(extension) && (
        <>
          <iframe
            src={filePath}
            onLoad={handleImageLoad}
            onError={handleImageError}
            frameBorder='0'
            className={`photo-popup-iframe ${isLoading ? 'img-loading' : ''}`}
          ></iframe>
        </>
      )}
    </section>
  );
};

export default PreviewFileAction;
