import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import File from './File';

const Folder = ({ item, selectedObject, handleSelectedItem }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIsExpanded = (data) => {
    handleSelectedItem(data);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (item?.isDefault && !isExpanded) {
      handleIsExpanded(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <>
      <div
        key={`folder_${item.id}`}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleIsExpanded(item)}
        className={selectedObject?.id === item.id ? 'selected' : ''}
      >
        {isExpanded
          ? <i className="bi bi-chevron-down"></i>
          : <i className="bi bi-chevron-right"></i>} {<Image src={item.src} width={16} height={16} alt='folder' />} {item.name}
      </div>
      {isExpanded && item.items && item.items.length > 0 && (<div
        key={item.id}
        className="objectTree"
      >
        {item.items.map((eachItem) => {
          let returnItem = '';
          if (eachItem.isFolder) {
            returnItem = <Folder key={`folder_${eachItem.id}`} item={eachItem} selectedObject={selectedObject} handleSelectedItem={handleSelectedItem} />;
          } else {
            returnItem = <File key={eachItem.id} item={eachItem} selectedObject={selectedObject} handleSelectedItem={handleSelectedItem} />;
          }
          return returnItem;
        })}
      </div>)}
    </>
  );
};

export default Folder;
