import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ThemesList = () => (
    <>
        <Row>
            <Col xs={12} md={12}>
                <div className="d-flex align-items-center mb_2 py-2 myProduct">
                    <h6 className="text-white mb-0 me-2">Themes</h6>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={12} className="productListTable">
                <p>Show Themes here</p>
            </Col>
        </Row>
    </>
);

export default ThemesList;
