// import { api } from './api';

const createFolder = async (name, parentId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, parentId }),
      });

      const jsonResponse = await response.json();

      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const deleteFile = async (ids) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/folder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: { ids } }),
      });

      const jsonResponse = await response.json();

      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const downloadFile = async (files) => {
  try {
    const fileQuery = files.map((file) => `files=${encodeURIComponent(file.id)}`).join('&');
    const url = `${import.meta.env?.VITE_API_BASE_URL}/download?${fileQuery}`;
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    return error;
  }

  return null;
};

const copyFiles = async (sourceIds, destinationId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/copy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sourceIds, destinationId }),
      });

      const jsonResponse = await response.json();

      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const moveFiles = async (sourceIds, destinationId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/move', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sourceIds, destinationId }),
      });

      const jsonResponse = await response.json();

      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const getAllFiles = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/files', { method: 'GET' });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const renameFile = async (id, newName) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch('/rename', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, newName }),
      });

      const jsonResponse = await response.json();

      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

export {
  createFolder, deleteFile, downloadFile, copyFiles, moveFiles, getAllFiles, renameFile,
};
