import { useEffect, useRef, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import SubMenu from './SubMenu';
import './ContextMenu.css';

const ContextMenu = ({
  filesViewRef, contextMenuRef, menuItems, visible, clickPosition,
}) => {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(null);
  const [subMenuPosition, setSubMenuPosition] = useState('right');

  const subMenuRef = useRef(null);

  const contextMenuPosition = () => {
    const { clickX, clickY } = clickPosition;

    const container = filesViewRef.current;
    const containerRect = container.getBoundingClientRect();
    // const scrollBarWidth = container.offsetWidth - container.clientWidth;

    // Context menu size
    const contextMenuContainer = contextMenuRef.current.getBoundingClientRect();
    const menuWidth = contextMenuContainer.width;
    const menuHeight = contextMenuContainer.height;

    // Check if there is enough space at the right for the context menu
    const leftToCursor = clickX;
    const isRight = containerRect.right - leftToCursor > menuWidth;
    const isLeft = !isRight;

    const topToCursor = clickY;
    const isTop = window.innerHeight - topToCursor > menuHeight;
    const bottom = !isTop;

    if (isRight) {
      setLeft(`${leftToCursor}px`);
      setSubMenuPosition('right');
    } else if (isLeft) {
      // Location: -width of the context menu from cursor's position i.e. left side
      setLeft(`${leftToCursor - menuWidth}px`);
      setSubMenuPosition('left');
    }

    if (isTop) {
      setTop(`${topToCursor}px`);
    } else if (bottom) {
      setTop(`${topToCursor - menuHeight}px`);
    }
    // debugger;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseOver = (index) => {
    setActiveSubMenuIndex(index);
  };

  useEffect(() => {
    if (visible && contextMenuRef.current) {
      contextMenuPosition();
    } else {
      setTop(0);
      setLeft(0);
      setActiveSubMenuIndex(null);
    }
  }, [visible]);

  if (visible) {
    return (
      <div
        ref={contextMenuRef}
        onContextMenu={handleContextMenu}
        onClick={(e) => e.stopPropagation()}
        className={`fm-context-menu ${top ? 'visible' : 'hidden'}`}
        style={{
          top,
          left,
        }}
      >
        <div className="file-context-menu-list">
          <ul>
            {menuItems
              .filter((item) => !item.hidden)
              .map((item, index) => {
                const hasChildren = item.hasOwnProperty('children');
                const activeSubMenu = activeSubMenuIndex === index && hasChildren;
                return (
                  <div key={item.title}>
                    <li
                      onClick={item.onClick}
                      className={`${item.className ?? ''} ${activeSubMenu ? 'active' : ''}`}
                      onMouseOver={() => handleMouseOver(index)}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                      {hasChildren && (
                        <>
                          <FaChevronRight size={14} className="list-expand-icon" />
                          {activeSubMenu && (
                            <SubMenu subMenuRef={subMenuRef} list={item.children} position={subMenuPosition} />
                          )}
                        </>
                      )}
                    </li>
                    {item.divider && <div className="divider"></div>}
                  </div>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default ContextMenu;
