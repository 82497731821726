import { useCallback, useEffect, useState } from 'react';
import { FaBrush } from 'react-icons/fa6';
import { Image } from 'react-bootstrap';
import productIcon from '../../../assets/images/virtual/productIcon.png';
import appIcon from '../../../assets/images/virtual/appIcon.png';
import getParentPath from '../../../utils/getParentPath';
import FolderTree from '../Explorer/FolderTree/FolderTree';
import { useFiles } from '../../../contexts/FilesContext';
import { useFileNavigation } from '../../../contexts/FileNavigationContext';

const MyAssetLibrary = ({
  optionSelected, handleShow, contentSelected,
}) => {
  const [foldersTree, setFoldersTree] = useState([]);
  const { files } = useFiles();
  const { setCurrentPath } = useFileNavigation();

  const handleShowClick = (option, data = {}) => {
    handleShow(option, data);
    option !== 1 && setCurrentPath(''); // Reset Path
  };

  const createChildRecursive = useCallback((path, foldersStruct) => {
    if (!foldersStruct[path]) return []; // No children for this path (folder)

    return foldersStruct[path]?.map((folder) => ({
      ...folder,
      subDirectories: createChildRecursive(folder.path, foldersStruct),
    }));
  }, []);

  useEffect(() => {
    if (Array.isArray(files)) {
      const folders = files.filter((file) => file.isDirectory);
      // Grouping folders by parent path
      const foldersStruct = Object.groupBy(folders, ({ path }) => getParentPath(path));
      setFoldersTree(() => {
        const rootPath = '';
        return createChildRecursive(rootPath, foldersStruct);
      });
    }
  }, [files, createChildRecursive]);

  return (
    <>
      <div className="fileExplorer">
        <h6 key="my_assets" className="text-white mb-0 assetsHeading">My Assets Library</h6>
        <div className="foldertree pt-3">
          {foldersTree?.map((folder, index) => <FolderTree key={index} folder={folder} handleShowClick={handleShowClick} />)}
          <p
            key="products"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(2)}
            className={optionSelected === 2 ? 'selected' : ''}
          >
            <Image src={productIcon} width={17} height={17} alt='product' /> Products
          </p>
          <p
            key="apps"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(3)}
            className={optionSelected === 3 ? 'selected' : ''}
          >
            <Image src={appIcon} width={17} height={18} alt='app' /> Apps
          </p>
          <p
            key="themes"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(4)}
            className={optionSelected === 4 ? 'selected' : ''}
          >
            <FaBrush size={16} color='gold' className='me-1' /> Themes
          </p>
        </div>
      </div>
    </>
  );
};

export default MyAssetLibrary;
