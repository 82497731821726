const formatDate = (date) => {
  let dateValue = date;
  if (!dateValue || isNaN(Date.parse(dateValue))) return '';

  dateValue = new Date(dateValue);
  let hours = dateValue.getHours();
  const minutes = dateValue.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  const month = dateValue.getMonth() + 1;
  const day = dateValue.getDate();
  const year = dateValue.getFullYear();

  return `${month}/${day}/${year} ${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`;
};

export default formatDate;
