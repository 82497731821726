import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { objectDelete } from '../../../../services/SolutionService';

const DeleteObjectModal = ({ showDeleteModal, handleShowDelete, selectedItem }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setShow(showDeleteModal);
  }, [showDeleteModal]);

  const handleRemoveObject = async () => {
    try {
      setIsLoading(true);
      const response = await objectDelete({ id: selectedItem?.id });
      if (response.success) {
        setIsLoading(false);
        handleShowDelete();
        toast.success(response.message);
      } else {
        setIsLoading(false);
        handleShowDelete();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowDelete();
      toast.error(error.message);
    }
  };

  return (
    <>
      <Modal className="maxWidth" show={show} onHide={handleShowDelete}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 modal-title h4">Delete Object</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you want to remove this item from the canvas?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="secondary" onClick={handleShowDelete}>
            No
          </Button>
          <Button variant="primary" onClick={handleRemoveObject} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteObjectModal;
