import {
  createContext, useContext, useEffect, useState,
} from 'react';

const LayoutContext = createContext();

function validateLayout(layout) {
  const currLayout = localStorage.getItem('FileManagerLayout') ?? layout;
  const acceptedValue = ['list', 'grid'];
  return acceptedValue.includes(currLayout) ? currLayout : 'grid';
}

const LayoutProvider = ({ children, layout }) => {
  const [activeLayout, setActiveLayout] = useState(() => validateLayout(layout));

  useEffect(() => {
    localStorage.setItem('FileManagerLayout', activeLayout);
  }, [activeLayout]);

  return (
    <LayoutContext.Provider value={{ activeLayout, setActiveLayout }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

export const useLayout = () => useContext(LayoutContext);
